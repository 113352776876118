<!--  -->
<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less">
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
.content_nav {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 5vh 0;
  background: #f5f5f5;
  a {
    position: relative;
    color: #333;
    font-size: 22px;
    opacity: 0.7;
    -webkit-tap-highlight-color: transparent;
    margin: 0 10px;
    text-decoration: none;
  }
  a.active {
    color: #c00000;
    font-weight: bold;
  }
  a.active::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 2px;
    opacity: 0.6;
    background-color: #c00000;
    bottom: -10px;
  }
}
</style>
